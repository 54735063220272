// 非表示項目
.top__header,
.top__main__banner_wrap,
.sidemenu
{ display: none; }

.m_top_header,
.m_top_main__image,
.m_top__main__banner_wrap,
.m_top_sidemenu
{ display: block; }

.main__wrap {
		padding: 15px 0 0px 0;
}    

.modal__js {
		width: 100%;
		position: relative;
		top: 0;
		right: 0;
}


.main {
		display: block;
		width: 100%;
		margin: 0 auto;
		padding: 0 10px 10px;
		box-sizing: border-box;
}


.m_top_header,
.sub_header {
	display: block;
	position: relative;
	height: 60px;
	width: 100%;
	background: #76CAEC;
	&__logo {
		display: block;
		position: absolute;
		top: 12px;
		left:20px;
		& img {
		height: 22px;
		width: auto;
		}
		height: 22px;
		width: auto;
	}
	&__disc {
		display: block;
		position: absolute;
		top: 40px;
		left: 20px;
		& img {
		height: 10px;
		width: auto;
		}
	}
	&__menu_link {
		display: block;
		position: absolute;
		top:20px;
		right:20px;
		width: 18px;
		height: 18px;
		text-align: right;
		& img {
			width: 18px;
			height: 18px;
		}
	}
	&__serch {
		display: block;
		position: absolute;
		top:20px;
		right: 50px;
		width: 18px;
		height: 18px;
		text-align: right;
		& img {
			width: 18px;
			height: 18px;
		}
	}
}

.m_top_main__image {
	width: 100%;
	height: auto;
	overflow: hidden;
	& img {
		display: block;
		width: 100%;
		background-color: #76CAEC;
	}
}

.m_top__main {
	&__banner_wrap {
		width: 100%;
		position: relative;
		padding: 0px 0px 20px 0px;
		margin: 0 0 10px 0;
		box-sizing: border-box;
	}
	&__banner {
		position: relative;
		width: 100%;
		padding: 10px 0 0px;
		box-sizing: border-box;
		margin: 0 auto 0px;
		&__image {
			position: relative;
			width: 100%;
			height: auto;
			margin: 0px 0px 0px 0px;
			& img {
				position: relative;
				width: 100%;
				height: auto;
				//margin: 0 10px;
			}
		}
	}
}

.top__main_wrap {
		display: block;
		position: relative;
		width: 100%;
		background: #2C446A;
		padding: 0 0 0 0;
		box-sizing: border-box;
		}
		
.top__main{
			width: 100%;
	&__tab_list  {
		display: block;
		position: relative;
		left: 0;
		float: none;
		margin: 0 5px 20px ;
		height: 55px;
	}
	&__tab  {
		display: block;
		position: relative;
		left :0;
		margin:  0 5px 10px;
		width: 75px;
		height: 23px;
		line-height: 23px;
		font-size: 1.2rem;
		float: left;
		text-align: center;
		cursor: pointer;
	}
}

.top__main__content_box,
.category__content_box {
		display: block;
		position: relative;
		width: 100%;
		height: auto;
		margin: 10px auto 10px;
		float: none;
		background: #fff;
}

body.unlogin .top__main__content_box {
		height: auto;
}

body.is-login .top__main__content_box {
		height: auto;
}

.relation_contents__content_box {
		display: block;
		position: relative;
		padding: 0 0 10px 0;
}

.relation_contents__content_box:nth-child(even) {
		width: 49%;
		height: auto;
		margin: 1% 1% 1.5% 0;
		float: left;
		background: #fff;
}

.relation_contents__content_box:nth-child(odd) {
		width: 49%;
		height: auto;
		margin: 1% 0px 1.5% 1%;
		float: left;
		background: #fff;
}

.top__main__content_box__thumbnail,
.relation_contents__content_box__thumbnail,
.category__content_box__thumbnail {
		display: block;
		position: relative;
		width: 100%;
		height: auto;
		overflow: hidden;
		&:before{
		content: "";
		display: block;
		width: 100%;
		padding-top: 56.25%;
	}
		& img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: auto;
		}
}

.relation_contents__wrap {
		padding: 20px 0 5px 0;
}

.relation_contents__content_box__title {
	font-size: 1.3rem;
	line-height: 1.35em;
	padding: 10px;
	box-sizing: border-box;
	height: 60px;
	overflow: hidden;
	& a {
	color: #4A4A4A;
	text-decoration: none;
	}
}

.top__main__content_box__tag_box {
		display: block;
		position: relative;
		bottom: 0;
		width: 100%;
		height: auto;
		padding: 10px 10px 5px;
		box-sizing: border-box;
}

.category__content_box__tag_box {
		display: block;
		position: relative;
		bottom: 0;
		width: 100%;
		height: auto;
		padding: 10px 10px 5px;
		box-sizing: border-box;
}

.top__main__content_box__title {
		font-size: 1.6rem;
		line-height: 1.5em;
		padding: 10px;
		box-sizing: border-box;
		height: auto;
		overflow: hidden;
}

.category__content_box__title {
		font-size: 1.6rem;
		line-height: 1.5em;
		padding: 10px;
		box-sizing: border-box;
		height: auto;
		overflow: hidden;
}

.works__content_box__thumbnail {
	width: 100%;
}

.m_top_sidemenu {
		width: 100%;
		margin: 0 0 0px 0;
		padding: 0 10px;
		box-sizing: border-box;
}
.sidemenu__box--auto {
		display: block;
		position: relative;
		width: 100%;
		height: auto;
		margin: 0px 0px 10px 0px;
		float: none;
		background: #D5E5E7;
}

.sidemenu__box {
		display: block;
		position: relative;
		width: 100%;
		height: auto;
		margin: 0px auto 10px;
		padding: 0 0 20px 0;
		float: none;
		background: #D5E5E7;
}

.sidemenu__box__content {
		display: block;
		position: relative;
		width: 100%;
		height: 50px;
		padding: 0 12px 0px 12px;
		margin: 0 0 12px 0;
		box-sizing: border-box;
		overflow: hidden;
}

.works__sidemenu__box{
		display: block;
		position: relative;
		width: 100%;
		height: auto;
		margin: 10px auto 0px;
		padding: 0 0 20px 0;
		float: none;
		background: #fff;
}

.footer {
	display: block;
	position: relative;
	width: 100%;
	background: #2C446A;
	padding:  10px 0 10px 0;
	box-sizing:border-box;
	clear: both;
	&__banner_wrap {
			border-top: none;
			padding: 0px 0 0px 0;
			margin: 0px 0 0px 0;
	}
	&__banner {
		position: relative;
		width: 100%;
		padding: 0px 10px;
		box-sizing: border-box;
		margin: 0 auto 20px;
		&__image {
				position: relative;
				float: none;
				width: 100%;
				height: auto;
				margin: 0px auto 10px;
				padding: 0 0px;
				box-sizing: border-box;
				& img {
					width: 100%;
					height: auto;
				}
		}
		&__follow--fb {
			width: 100%;
				float: none;
			margin: 0px 0px 10px 0;
			padding: 15px 0 15px 0;
		}
		&__follow--tw {
			width: 100%;
				float: none;
				margin: 0px 0px 10px 0;
				padding: 15px 0 15px 0;
		}
	}
}

.sidemenu__banner__follow--fb {
	width: 50%;
	float: left;
	margin: 0px 0px 0px 0;
	padding: 15px 0 15px 0;
}
.sidemenu__banner__follow--tw {
	width: 50%;
		float: right;
		margin: 0px 0px 0px 0;
		padding: 15px 0 15px 0;
}

.contents__main,
.contents__main__1column  {
		display: block;
		width: 100%;
		float: none;
		margin: 0px 0px 0px 0px;
		padding: 5px 20px;
		background: #fff;
		box-sizing: border-box;
	& h2,h3  {
		font-size: 2.2rem;
		line-height:  1.8em;
		margin: 1em 0 0.2em 0;
		color: #2C446A;
	}
	& p {
		font-size: 1.7rem;
		line-height:  1.8em;
		text-align: justify;
		margin: 0 0 1em 0;
		color: #2C446A;
	}
	& iframe {
		width: 100%;
		margin: 0px auto 0px;
		padding-bottom: 57.5%;
		padding: 0 0 0 0;
	}
	& img {
		width: 100%;
		height: auto;
	}
}



.contents__header{
		display: block;
		position: relative;
		height: auto;
		width: 100%;
		padding: 0 0px;
		box-sizing: border-box;
	&__category_box {
		display: block;
		width: 100%;
		margin: 0px 0 10px 0;
		padding:0px 0 0px 0;
		box-sizing: border-box;
		text-align: left;
	}
	&__category {
		display: inline-block;
		width: auto;
		height: 23px;
		line-height: 23px;
		margin: 0;
		padding: 0 9px;
		text-align: center;
		font-size: 1.2rem;
		font-weight: bold;
			&  a {
					text-decoration: none;
		}
	}
	&__date {
		display: block;
			margin: 0px 0 12px 0;
	}
	&__title {
		font-size: 2.2rem;
		margin: 0px 0 15px 0;
		line-height: 1.5em;
	}
	&__tag_box {
		display: block;
		width: 100%;
		height: auto;
		padding: 0px 0px 10px;
		box-sizing: border-box;
	}
	&__tag {
		display: block;
		font-size: 1rem;
		border: 1px solid #2C446A;
		padding: 3px 5px;
		margin: 0 5px 5px 0;
		float: left;
		border-radius: 3px;
		& a {
			text-decoration: none;
			color: #2C446A;
		}
	}
}

.contents__main__image {
		display: block;
		width: 106%;
		max-height: 506px;
		overflow: hidden;
		margin: 0 0px 30px -10px;
		box-sizing: border-box;
	& img {
		width: 100%;
		height: auto;
	}
}
.contents__main__image {
		display: block;
		position: relative;
		width: 100%;
		height: auto;
		overflow: hidden;
		margin: 0 0px 20px 0px;
		background: #fff;
		padding-bottom: 57.5%;
	& img {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: auto;
	}
}

.contents__main__image.is_video {
		padding-bottom: 0;
		}

.contents__main__image--works {
		display: block;
		position: relative;
		text-align: center;
		top: inherit;
		right: inherit;
		width: 100%;
		height: initial;
		padding: 10px;
		float: none;
		overflow: hidden;
		background: #D5E5E7;
		box-sizing: border-box;
		margin: 0px 0 20px 0;
	& img {
		height: 240px;
		width: auto;
	}
}

.contents__footer {
		display: block;
		width: auto;
		float: none;
		margin: 15px 0px 0px 0;
		padding: 0px 15px;
		background: none;
		box-sizing: border-box;
}

.contents__sidemenu {
		display: block;
		position: relative;
		float: none;
		background: none;
		margin: 0px auto 0px auto;
}

.top__main__content_wrap {
		display: block;
		width: 100%;
		padding: 0 10px;
		box-sizing: border-box;
}

.category__title {
		margin: 0px 0 10px 0;
}

.category__main__image {
		display: block;
		position: relative;
		width: 100%;
		height: auto;
		overflow: hidden;
		margin: 0 0px 20px 0px;
		background: #fff;
		padding-bottom: 57.5%;
	& img {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: auto;
	}
}

.series_contents {
	&__wrap {
		display: block;
		background: #2C446A;
		padding: 10px 0 0px 0;
	}
	&__main {
		display: block;
		background: #2C446A;
		width: 100%;
		float: none;
			margin: 0 0 40px;
	}
	&__title {
		color: #FFF;
		font-size: 3.2rem;
		margin: 0px 0 10px 0;
		line-height: 1.5em;
		text-align: center;
	}
}

.series_contents {
	&__content_box{
		display: block;
		position: relative;
		width: 100%;
		/* min-height: 110px; */
		height: auto;
		margin: 0px 0px 10px 0px;
		padding: 15px;
		float: none;
		background: #fff;
		box-sizing: border-box;
		&__category_box {
				display: block;
				width: auto;
				height: 35px;
				line-height: 50px;
				margin: 0;
				padding: 0px 0 0 0px;
				box-sizing: border-box;
				text-align: left;
				float: left;
		}
		&__category {
			display: block;
			width: auto;
			height: 22px;
			line-height: 22px;
			margin: 0;
			padding: 0 12px;
			text-align: center;
			float: left;
		}
		&__thumbnail {
				display: block;
				position: relative;
				top: 0px;
				right: 0px;
				width: 50%;
				padding-bottom: 28.75%;
				height: initial;
				float: right;
				overflow: hidden;
				& img {
					height: auto;
						width: 100%;
						position: absolute;
						top: 0;
						left: 0;
				}
		}
		&__title{
				font-size: 1.8rem;
				line-height: 1.5em;
				padding: 0px 0 0px 0px;
				box-sizing: border-box;
				height: auto;
				overflow: hidden;
				clear: left;
			& a {
			color: #4A4A4A;
			text-decoration: none;
			}
		}
		&__tag_box {
			display: block;
			width: 100%;
			height: 70px;
			padding: 15px 15px ;
			box-sizing: border-box;
		}
		&__tag {
			display: block;
			font-size: 1rem;
			border: 1px solid #2C446A;
			padding: 3px 5px;
			margin: 0 5px 5px 0;
			float: left;
			border-radius: 3px;
			& a {
				text-decoration: none;
				color: #2C446A;
			}
		}
		&__disc {
			display: none;
			width: 100%;
			height: auto;
			padding: 0px 20px 0 20px;
			box-sizing: border-box;
			font-size: 1.4rem;
		}
	}
}

.relation_contents__content_box__tag_box,
.series_contents__content_box__tag_box,
.works_series_contents__content_box__tag_box {
	position: relative;
}

.series_contents__sidemenu {
	display: block;
	position: relative;
	width: 100%;
	float: right;
	background: none;
	margin: 0px 0px -10px 0;
	}

.works_series_contents {
	&__content_box{
			display: block;
			position: relative;
			width: 100%;
			height: auto;
			margin: 0px 0px 10px 0px;
			padding: 10px;
			box-sizing: border-box;
			float: none;
			background: #fff;
		&__category_box {
				display: block;
				width: 100%;
				height: 40px;
				line-height: 50px;
				margin: 0;
				padding: 10px 0 0 10px;
				box-sizing: border-box;
				text-align: left;
		}
		&__category {
			display: block;
			width: auto;
			height: 22px;
			line-height: 22px;
			margin: 0;
			padding: 0 12px;
			text-align: center;
			float: left;
		}
		&__thumbnail {
					display: block;
					position: relative;
					text-align: center;
					top: inherit;
					right: inherit;
					width: 100%;
					height: initial;
					padding: 10px;
					float: none;
					overflow: hidden;
					background: #D5E5E7;
					box-sizing: border-box;
					margin: 0px 0 0 0;
				& img {
						height: 240px;
						width: auto;
				}
		}
		&__title{
				font-size: 1.8rem;
				line-height: 1.5em;
				padding: 5px 0 0px 10px;
				box-sizing: border-box;
				height: auto;
				overflow: hidden;
			& a {
			color: #4A4A4A;
			text-decoration: none;
			}
		}
		&__tag_box {
				display: block;
				width: 100%;
				height: auto;
				padding: 10px 15px 0px 10px;
				box-sizing: border-box;
		}
		&__tag {
				display: block;
				font-size: 1rem;
				border: 1px solid #2C446A;
				padding: 3px 5px;
				margin: 0 5px 5px 0;
				float: left;
				border-radius: 3px;
			& a {
				text-decoration: none;
				color: #2C446A;
			}
		}
		&__disc {
				display: block;
				width: 100%;
				height: auto;
				padding: 5px 20px 10px 10px;
				box-sizing: border-box;
				font-size: 1.4rem;
				line-height: 1.5em;
		}
	}
}

.sub_category__main__wrap {
	display: block;
	width: 100%;
	margin: 0 auto;
}

.sub_category__main {
	display: block;
	width: 100%;
	margin: 0 auto;
	float: left;
}

.sub_category__more_button {
	top:0px;
		width: 100%;	
}

.sub_category__sidemenu {
	display: block;
	position: relative;
	float: right;
	background: none;
	margin: 15px 0px -10px 0;
	}

// コンテンツ内テンプレート
.book_list {
		display: block;
		border: 1px solid #979797;
		margin:  0 0 20px 0;
		padding: 0;
}

.book_list__image {
	display: block;
		position: relative;
		text-align: center;
		top: inherit;
		right: inherit;
		width: 100%;
		height: initial;
		padding: 10px;
		float: none;
		overflow: hidden;
		background: #D5E5E7;
		box-sizing: border-box;
		margin: 0px 0 10px 0;
		& img {
	height: 240px;
		width: auto;
		margin: 10px 0 0 0;
		}
}

.book_list__title {
		display: block;
		margin: 0 0 10px 10px;
		font-size: 1.6rem;
		font-weight: bold;
}

.book_list {
	&__read {
			display: block;
			width: 100%;
			height: 35px;
			padding: 0px 10px 15px;
			box-sizing: border-box;
	}
	&__read li a{
		display: block;
		font-size: 1.2rem;
		background: #F7F7BF;
		border: 1px solid #F6A623;
		padding: 0px 10px;
		line-height: 20px;
		margin: 0 10px 5px 0;
		float: left;
		border-radius: 3px;
		text-decoration: none;
		color: #2C446A;
		font-weight: bold;
	}
}

.box_404 {
		width: 100%;
		padding: 0px 10px 40px;
		max-width: 390px;
		margin: 0 auto;
		box-sizing: border-box;
}

.slick-slide {
  margin: 0 5px;
}

.slick-list {
  margin: 0 0px;
}

.slick-dots li button:before {
  color: #fff;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  color: #76CAEC;
  opacity: 1;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  margin: 0 0px;
}
